// PINIA STORE handling the USER AUTHENTICATION

import axios from "axios";
import swal from "sweetalert";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("authentication", {
  state: () => {
    return {
      token_string: null,
      username: null,
      user_id: null,
      user_org: null,
    };
  },
  getters: {
    token: (state) => "Bearer " + state.token_string,
  },
  actions: {
    login(loginForm) {
      axios
        .post("/api/login", loginForm)
        .then((res) => {
          this.token_string = res.data.access_token;
          this.setUser();
          swal({
            text: "Login in ...",
            icon: "success",
            timer: 2000,
            button: false,
          });
        })
        .catch(function (error) {
          // Using Sweetalert for personnalized alerts:
          // https://sweetalert.js.org/guides/#getting-started

          const status_code = error.response.status;
          if (status_code === 401) {
            swal({
              text: "Invalid credentials",
              icon: "error",
              dangerMode: true,
            });
          } else {
            console.error({
              status: error.response.status,
              message: error.response.data,
            });
          }
        });
    },
    setUser() {
      axios
        .get("/api/user", {
          headers: { Authorization: this.token },
        })
        .then((res) => {
          this.username = res.data.username;
          this.user_id = res.data.user_id;
          this.user_org = res.data.organization;
        })
        .catch((err) => {
          console.error({
            status: err.response.status,
            message: err.response.data,
          });
        });
    },
  },
});
