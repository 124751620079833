<template>
  <Navbar />
  <router-view />
</template>

<script setup></script>

<style lang="scss">
body {
  background: #23787d;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    .router-link-exact-active {
      color: #42b983;
    }
  }
}

.color-light {
  color: #f8f9fa;
}
.btn {
  padding-top: 8px;
  padding-bottom: 5px;

  &.btn-primary {
    background-color: #41b883;
    border: 1px solid #41b883;

    &:hover {
      background-color: transparent;
      border: 1px solid #41b883;
      color: #41b883;
    }
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(65, 184, 131, 0.5);
    }
  }

  &.btn-secondary {
    background-color: #212529;
    border: 1px solid #212529;
    color: #f8f9fa;

    &:hover {
      background-color: #35495e;
      border: 1px solid #35495e;
    }
  }
}
</style>
