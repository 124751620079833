<template>
  <section class="vh-100 gradient-custom">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div class="card bg-dark text-white" style="border-radius: 1rem">
            <div class="card-body p-5 text-center">
              <form class="mb-md-5 mt-md-4" v-on:submit.prevent="login">
                <h2 class="fw-bold mb-2 text-uppercase">Login</h2>
                <p class="text-white-50 mb-5">
                  Please enter your login and password!
                </p>

                <div class="form-outline form-white mb-4">
                  <input
                    v-model.trim="username"
                    type="text"
                    id="username"
                    name="username"
                    class="form-control form-control-lg"
                    placeholder="Login"
                  />
                </div>

                <div class="form-outline form-white mb-4">
                  <input
                    v-model.trim="password"
                    type="password"
                    id="typePasswordX"
                    name="password"
                    class="form-control form-control-lg"
                    placeholder="Password"
                  />
                </div>

                <p class="small mb-5 pb-lg-2">
                  <a class="text-white-50" href="#!">Forgot password?</a>
                </p>

                <button class="btn btn-outline-light btn-lg px-5" type="submit">
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authentication";

// Variables
const store = useAuthStore();
store.$reset();

// V-Models
const username = ref("");
const password = ref("");

// Routes
const router = useRouter();

async function login() {
  // To see how to get Form-Data:
  // https://axios-http.com/docs/urlencoded

  // Build the request body
  const FormData = require("form-data");
  const loginForm = new FormData();
  loginForm.append("username", username.value);
  loginForm.append("password", password.value);

  await store.login(loginForm);
  setTimeout(() => {
    router.push({ name: "Dashboard" });
  }, 1750);
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.gradient-custom {
  /* fallback for old browsers */
  background: #23787d;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    rgba(35, 120, 125, 1),
    rgba(153, 51, 102, 1),
    rgba(35, 120, 125, 1)
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to right,
    rgba(35, 120, 125, 1),
    rgba(153, 51, 102, 1),
    rgba(35, 120, 125, 1)
  );
}
</style>
