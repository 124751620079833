<template>
  <section>
    <div class="container bg-dark px-3 py-4 my-5">
      <div class="bg-light px-3 py-3">
        <h3 class="align-middle shadow border rounded pt-2 mb-5">
          {{ authStore.username ? authStore.username : "" }}'s Dashboard
        </h3>
        <p class="text-start">
          Clickado is an open-source software that allows the population and
          download of templates. Start by setting up your Clickado account, then
          execute it to your heart's content. If at any point you feel lost and
          don't know what to do, feel free to have a look at our user guide.
        </p>
      </div>

      <!-- Cards -->
      <div class=""></div>
      <div class="container d-flex justify-content-center bg-dark px-3 py-4">
        <div class="row d-flex justify-content-center">
          <Card
            v-for="(card, index) in cardList"
            :key="index"
            :title="card.title"
            :text="card.text"
            :button="card.button"
            :imageSrc="card.imageSrc"
            :linkTo="card.linkTo"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authentication";
import Card from "@/components/DashboardCard.vue";
import SettingsImage from "@/assets/settings.png";
import ClickadoImage from "@/assets/execute.png";
import UserGuideImage from "@/assets/user_guide.png";

// Variables
const authStore = useAuthStore();
const router = useRouter();

// V-Models
const cardList = ref([
  {
    title: "Execute",
    text: "Get to form and Clickado !",
    imageSrc: ClickadoImage,
    linkTo: "/execute",
  },
  {
    title: "Settings",
    text: "Upload or select a Clickado Form and / or the Template to be used.",
    imageSrc: SettingsImage,
    linkTo: "/settings",
  },
  {
    title: "User Guide",
    text: "If it's your first time coming here, read this first.",
    imageSrc: UserGuideImage,
    linkTo: { name: "Guide" },
  },
]);

// Methods
onMounted(() => {
  if (!authStore.user_id) {
    router.push({ name: "Login" });
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.light-background {
  padding-top: 1em;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
