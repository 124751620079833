import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/Login";
import Dashboard from "@/views/Dashboard";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunckName: "settings" */ "@/views/Settings.vue"),
  },
  {
    path: "/execute",
    name: "Execute",
    component: () =>
      import(/* webpackChunckName: "execute" */ "@/views/Execute.vue"),
  },
  {
    path: "/guide",
    name: "Guide",
    component: () =>
      import(/* webpackChunckName: "execute" */ "@/views/Guide.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation Guard (mandatory login)
// function isUserAllowed() {
//   return false;
// }
// router.beforeEach((to) => {
//   console.log("To: ", to);
//   if (to != "Login") {
//     const isAllowed = isUserAllowed(to);
//     if (!isAllowed) return "Login";
//   }
// });

export default router;
