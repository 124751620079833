<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <!-- BRAND LOGO -->
      <span class="navbar-brand mb-0 h1"></span>

      <!-- NAVIGATION TOGGLE -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- NAVIGATION LINKS -->
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Dashboard' }"
              >Dashboard</router-link
            >
          </li>
          <li class="nav-item" v-if="store.user_id">
            <router-link
              class="nav-link"
              :to="{ name: 'Login' }"
              v-on:click="logout"
              >Logout</router-link
            >
          </li>
          <li class="nav-item" v-else>
            <router-link class="nav-link" :to="{ name: 'Login' }"
              >Login</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authentication";

// Variables
const store = useAuthStore();
const router = useRouter();

// Methods
const logout = () => {
  localStorage.removeItem("token");
  store.$reset();
  router.push({ name: "Login" });
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.navbar-brand {
  background: url("../assets/clickado_text_white.png") no-repeat;
  background-size: contain;
  width: 164px;
  height: 50px;
}
</style>
