import { createApp } from "vue";
import App from "./App.vue";
import router from "./plugins/router.js";
// import store from "./plugins/vuex.js";
import "./plugins/axios.js";
import Navbar from "./components/Navigation.vue";
import { createPinia } from "pinia";

const clickadoClient = createApp(App);

// Pass the Vue-Router to the app
clickadoClient.use(router);

// Pass the Pinia store to the app
clickadoClient.use(createPinia());

// Pass the Vuex store to the app
// clickadoClient.use(store);

// Make the component Navbar availaible to all components
clickadoClient.component("Navbar", Navbar);

// Mount (launch) app
clickadoClient.mount("#app");
